import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, Optional, APP_INITIALIZER, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import * as i3 from 'angular-svg-icon';
import { Subject, EMPTY } from 'rxjs';
import { tap, catchError, take } from 'rxjs/operators';
class AngularSvgIconPreloaderConfig {
  constructor() {
    this.configUrl = './assets/icons.json';
  }
}
class AngularSvgIconPreloaderService {
  constructor(_http, config, _iconRegistry) {
    this._http = _http;
    this._iconRegistry = _iconRegistry;
    this.configUrl = './assets/icons.json';
    this.iconsFileData = {
      customIcons: [],
      iconImageFiles: []
    };
    this.configSubject = new Subject();
    if (config && config.configUrl) {
      this.configUrl = config.configUrl;
    }
  }
  loadConfig() {
    return this._http.get(this.configUrl).pipe(tap(configData => {
      this.iconsFileData = configData;
      this.loadIcons();
    }), catchError(err => {
      console.error('An error occurred loading the icons:\n', err, '\nNo icons will be loaded.');
      this.iconsFileData = {
        customIcons: [],
        iconImageFiles: []
      };
      this.loadIcons();
      return EMPTY;
    }));
  }
  loadIcons() {
    this.iconsFileData.iconImageFiles.forEach(i => {
      const iconObs = this._iconRegistry.loadSvg(i.iconPath, i.iconName);
      if (iconObs) {
        iconObs.pipe(take(1)).subscribe();
      }
    });
    this.iconsFileData.customIcons.forEach(i => {
      this._iconRegistry.addSvg(i.iconName, i.iconData);
    });
  }
  static {
    this.ɵfac = function AngularSvgIconPreloaderService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularSvgIconPreloaderService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(AngularSvgIconPreloaderConfig, 8), i0.ɵɵinject(i3.SvgIconRegistryService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AngularSvgIconPreloaderService,
      factory: AngularSvgIconPreloaderService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularSvgIconPreloaderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: AngularSvgIconPreloaderConfig,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i3.SvgIconRegistryService
  }], null);
})();
function initConfig(svgSvc) {
  return () => svgSvc.loadConfig();
}
class AngularSvgIconPreloaderModule {
  static forRoot(config) {
    return {
      ngModule: AngularSvgIconPreloaderModule,
      providers: [{
        provide: AngularSvgIconPreloaderConfig,
        useValue: config
      }, AngularSvgIconPreloaderService]
    };
  }
  static {
    this.ɵfac = function AngularSvgIconPreloaderModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularSvgIconPreloaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AngularSvgIconPreloaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AngularSvgIconPreloaderService, {
        provide: APP_INITIALIZER,
        useFactory: initConfig,
        deps: [AngularSvgIconPreloaderService],
        multi: true
      }],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularSvgIconPreloaderModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [AngularSvgIconPreloaderService, {
        provide: APP_INITIALIZER,
        useFactory: initConfig,
        deps: [AngularSvgIconPreloaderService],
        multi: true
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AngularSvgIconPreloaderModule, AngularSvgIconPreloaderService, initConfig };
